import React from 'react'
import { graphql } from 'gatsby'
import EmbeddedContent, {
  EmbeddedContentContent,
} from 'src/components/organisms/general/embeddedContent'
import { PrismicPageDataBodyEmbeddedContent } from 'src/typings/generated/graphql'

type Props = {
  slice: PrismicPageDataBodyEmbeddedContent
}

const EmbeddedContentSlice = ({ slice }: Props) => {
  const content: EmbeddedContentContent = {
    anchor: slice.primary.anchor ?? undefined,
    url: slice.primary.link_to_embed?.url ?? '',
  }

  return <EmbeddedContent content={content} />
}

export default EmbeddedContentSlice

export const EmbeddedContentFragment = graphql`
  fragment PageDataBodyEmbeddedContent on PrismicPageDataBodyEmbeddedContent {
    primary {
      anchor
      link_to_embed {
        url
      }
    }
  }
`
