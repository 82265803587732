import React from 'react'
import {
  PrismicMenuItem,
  PrismicLinkType,
  Maybe,
} from 'src/typings/generated/graphql'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import BasicLinkCard from 'src/components/molecules/cards/basicLinkCard'

import { generateRandomId } from 'src/utils/domHelper'

import * as Styles from './basicSlider.module.scss'

export type BasicSliderItem = {
  title?: string
  image?: IGatsbyImageData
  imageAlt?: string
  link?: PrismicLinkType
  linkText?: string
  overrideTitleWithLink?: boolean
  overrideImageWithLink?: boolean
}

export type BasicSliderContent = {
  anchor?: string
  items: BasicSliderItem[]
}

type Props = {
  content: BasicSliderContent
}

const BasicSlider = ({ content }: Props) => {
  const { anchor = generateRandomId(), items } = content

  const generateSlides = () =>
    items.map((item, i: number) => {
      let { title = '', image, imageAlt = '' } = item
      const {
        link,
        linkText = '',
        overrideTitleWithLink = false,
        overrideImageWithLink = false,
      } = item

      // cast the link in case title or image is overridden
      const menuItem: Maybe<PrismicMenuItem> =
        link && link.type === 'menu_item'
          ? (link.document as PrismicMenuItem)
          : null

      if (overrideTitleWithLink) {
        if (menuItem) title = menuItem.data.name ?? title
      }

      if (overrideImageWithLink) {
        if (menuItem) {
          image = menuItem.data.image?.gatsbyImageData
          imageAlt = menuItem.data.image?.alt ?? imageAlt
        }
      }

      const key = `basic-slider-${i}`

      return (
        <div className={Styles.slide} key={key}>
          <BasicLinkCard
            title={title}
            image={image}
            imageAlt={imageAlt}
            link={link}
            linkText={linkText}
          />
        </div>
      )
    })

  return (
    <section id={anchor} className={Styles.basicSlider}>
      <div id={`slider-wrapper-${anchor}`} className={Styles.sliderWrapper}>
        {generateSlides()}
      </div>
    </section>
  )
}

export default BasicSlider
