import { graphql } from 'gatsby'
import React from 'react'

import { PrismicPageDataBodyDynamicCta } from 'src/typings/generated/graphql'
import DynamicCta, {
  DynamicCtaContent,
} from 'src/components/organisms/ctas/dynamicCta'

type Props = {
  slice: PrismicPageDataBodyDynamicCta
}

const DynamicCtaSlice = ({ slice }: Props) => {
  const content: DynamicCtaContent = {
    anchor: slice.primary.anchor ?? undefined,
    eyebrowText: slice.primary.eyebrow_text ?? undefined,
    headline: slice.primary.headline ?? '',
    description: slice.primary.description ?? undefined,
    link: slice.primary.link ?? undefined,
    linkText: slice.primary.link_text ?? undefined,
    linkStyle: slice.primary.link_style ?? undefined,
    image: slice.primary.image?.gatsbyImageData ?? undefined,
    imageAlt: slice.primary.image?.alt ?? undefined,
    imageLayout: slice.primary.image_layout ?? undefined,
    imageSize: slice.primary.image_size ?? undefined,
    mobileOnly: slice.primary.mobile_only ?? undefined,
  }

  return <DynamicCta content={content} />
}

export default DynamicCtaSlice

export const dynamicCtaFragment = graphql`
  fragment PageDataBodyDynamicCta on PrismicPageDataBodyDynamicCta {
    primary {
      anchor
      eyebrow_text
      headline
      description {
        richText
      }
      link_text
      link {
        ...DynamicLinkFragment
      }
      link_style
      image {
        alt
        gatsbyImageData(layout: CONSTRAINED)
      }
      image_layout
      image_size
      mobile_only
    }
  }
`
