// extracted by mini-css-extract-plugin
export var copy = "descriptiveCard-module--copy--dfc5d";
export var description = "descriptiveCard-module--description--502ce";
export var descriptionClosed = "descriptiveCard-module--description-closed--7e954";
export var descriptiveCard = "descriptiveCard-module--descriptive-card--a08fb";
export var headline = "descriptiveCard-module--headline--1aec3";
export var image = "descriptiveCard-module--image--6d797";
export var imageWrapper = "descriptiveCard-module--image-wrapper--03dad";
export var scaleHeadlineFont = "descriptiveCard-module--scale-headline-font--ae56d";
export var title = "descriptiveCard-module--title--45d45";
export var toggle = "descriptiveCard-module--toggle--b2211";