import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import BertholdTitle from 'src/components/atoms/bertholdTitle'

import { customScrollTo, generateRandomId } from 'src/utils/domHelper'
import * as Styles from './secondaryNavigation.module.scss'

export type SecondaryNavigationItem = {
  anchor?: string
  navigationText?: string
}

export type SecondaryNavigationContent = {
  items?: SecondaryNavigationItem[]
}

type Props = {
  content: SecondaryNavigationContent
}

const SecondaryNavigation = ({ content }: Props) => {
  const ref = useRef() as MutableRefObject<HTMLElement>
  const { items = [] } = content

  const [activeItem, setActiveItem] = useState(items[0].anchor ?? '')

  // determines which nav item should be set to active based on scroll position
  const detectTopSlice = () => {
    const boundingRect = ref.current.getBoundingClientRect()
    const yTarget = boundingRect.y + boundingRect.height + 1
    const topSlice = document
      .elementsFromPoint(window.innerWidth / 2, yTarget)
      .find(el => el.tagName.match(/section/gi)) as Element
    if (topSlice && topSlice.id) setActiveItem(topSlice.id)
  }

  useEffect(() => {
    detectTopSlice()
    window.addEventListener('scroll', detectTopSlice, true)

    return () => {
      window.removeEventListener('scroll', detectTopSlice, true)
    }
  })

  return (
    <section className={Styles.secondaryNavigation} ref={ref}>
      <div className={Styles.navItems}>
        {items.map((item, i: number) => {
          const { anchor = generateRandomId(), navigationText = '' } = item
          let className = Styles.navItem
          if (activeItem === anchor) className += ` ${Styles.active}`
          const key = `secondary-nav-${i}`

          const navigationHandler = () => {
            customScrollTo(`#${item.anchor}`, 'smooth')
            const targetItem = document.getElementById(`${item.anchor}`)
            targetItem?.focus()
            setTimeout(() => setActiveItem(anchor), 500)
          }

          return (
            <div className={className} key={key}>
              <div
                onClick={navigationHandler}
                onKeyDown={e => {
                  if (e.code === 'Enter') {
                    navigationHandler()
                  }
                }}
                role="link"
                tabIndex={0}
              >
                {BertholdTitle({ text: navigationText })}
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}
export default SecondaryNavigation
