/* eslint-disable no-param-reassign */

import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import * as Styles from './verticalImageReel.module.scss'

export enum VerticalImageReelDirection {
  up = 'up',
  down = 'down',
}

export type VerticalImageReelImages = {
  image?: IGatsbyImageData
  imageAlt?: string
}

type Props = {
  images: Array<VerticalImageReelImages>
  direction?: VerticalImageReelDirection
  paused: boolean
}

const VerticalImageReel = ({
  images,
  direction = VerticalImageReelDirection.up,
  paused,
}: Props) => {
  let imageCount = images.length

  if (!imageCount) return null

  // Establish 10 individual photos that are used twice each for animation
  if (images.length > 10) {
    images = images.splice(0, 10)
    images.push(...images)
    imageCount = images.length
  } else {
    while (images.length < 20) {
      images.push(...images.slice(0, imageCount))
    }
    images = images.splice(0, 20)
  }

  const upAnimationClasses = [
    Styles.slideUp1,
    Styles.slideUp2,
    Styles.slideUp3,
    Styles.slideUp4,
    Styles.slideUp5,
    Styles.slideUp6,
    Styles.slideUp7,
    Styles.slideUp8,
    Styles.slideUp9,
    Styles.slideUp10,
  ]

  const downAnimationClasses = [
    Styles.slideDown1,
    Styles.slideDown2,
    Styles.slideDown3,
    Styles.slideDown4,
    Styles.slideDown5,
    Styles.slideDown6,
    Styles.slideDown7,
    Styles.slideDown8,
    Styles.slideDown9,
    Styles.slideDown10,
  ]

  let imageClassName = Styles.imageContainer

  if (direction === VerticalImageReelDirection.down)
    imageClassName += ` ${downAnimationClasses[imageCount - 1]}`
  else imageClassName += ` ${upAnimationClasses[imageCount - 1]}`

  if (paused) imageClassName += ` ${Styles.paused}`

  return (
    <div className={Styles.verticalImageReel}>
      {images.map((item, i: number) => {
        const key = `vertical-image-reel-${i}`
        const { image, imageAlt = '' } = item
        return (
          <div className={imageClassName} key={key}>
            {!!image && (
              <GatsbyImage
                loading="eager"
                image={image}
                alt={imageAlt}
                className={Styles.image}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default VerticalImageReel
