import React from 'react'

import { PrismicSliceType } from 'src/typings/generated/graphql'

import ArticlesHeroSlice from 'src/slices/heros/articlesHeroSlice'
import ArticlesLibrarySlice from 'src/slices/engagement/articlesLibrarySlice'
import ArtOfAPerfectMealSlice from 'src/slices/productRouting/artOfAPerfectMealSlice'
import BannerCtaSlice from 'src/slices/ctas/bannerCtaSlice'
import BasicHeroSlice from 'src/slices/heros/basicHeroSlice'
import BasicSliderSlice from 'src/slices/productRouting/basicSliderSlice'
import CallToActionSlice from 'src/slices/ctas/callToActionSlice'
import CardsCtaSlice from 'src/slices/ctas/cardsCtaSlice'
import CommunityInvolvementSlice from 'src/slices/ctas/communityInvolvementSlice'
import CrewCalloutSlice from 'src/slices/crewConversion/crewCalloutSlice'
import CrewRisingSlice from 'src/slices/crewConversion/crewRisingSlice'
import DescriptiveSliderSlice from 'src/slices/slidersAndGrids/descriptiveSliderSlice'
import DoubleCtaSlice from 'src/slices/ctas/doubleCtaSlice'
import DynamicCtaSlice from 'src/slices/ctas/dynamicCtaSlice'
import ECardWidgetSlice from 'src/slices/general/eCardWidgetSlice'
import EmbeddedContentSlice from 'src/slices/general/embeddedContentSlice'
import FaqCategorySlice from 'src/slices/general/faqCategorySlice'
import FaqCtaSlice from 'src/slices/ctas/faqCtaSlice'
import FaqSlice from 'src/slices/general/faqSlice'
import HeroSliderSlice from 'src/slices/heros/heroSliderSlice'
import HighlightsSlice from 'src/slices/brandEducation/highlightsSlice'
import HostFundraiserSlice from 'src/slices/ctas/hostFundraiserSlice'
import HowItWorksSlice from 'src/slices/brandEducation/howItWorksSlice'
import ImageGridSlice from 'src/slices/slidersAndGrids/imageGridSlice'
import ImageReelSlice from 'src/slices/ctas/imageReelSlice'
import ImageStripSlice from 'src/slices/slidersAndGrids/imageStripSlice'
import LocatorSlice from 'src/slices/restaurantLocator/locatorSlice'
import LogoCTASlice from 'src/slices/ctas/logoCtaSlice'
import MenuItemGroupSlice from 'src/slices/productRouting/menuItemGroupSlice'
import MinimalHeroSlice from 'src/slices/heros/minimalHeroSlice'
import NewsroomArticlesLibrarySlice from 'src/slices/engagement/newsroomArticlesLibrarySlice'
import ProfileHighlightSlice from 'src/slices/brandEducation/profileHighlightSlice'
import QuoteSlice from 'src/slices/general/quoteSlice'
import RecentArticlesSlice from 'src/slices/engagement/recentArticlesSlice'
import RichTextCtaSlice from 'src/slices/ctas/richTextCtaSlice'
import SaucyCarouselSlice from 'src/slices/engagement/saucyCarouselSlice'
import SecondaryNavigationSlice from 'src/slices/general/secondaryNavigationSlice'
import StatGridSlice from 'src/slices/slidersAndGrids/statGridSlice'
import TimelineSlice from 'src/slices/brandEducation/timelineSlice'
import TitleAndDescriptionSlice from 'src/slices/general/titleAndDescriptionSlice'
import UpcomingRestaurantsSlice from 'src/slices/restaurantLocator/upcomingRestaurantsSlice'

interface Props {
  sliceZone: PrismicSliceType[]
}

const SliceZone = ({ sliceZone }: Props) => {
  const sliceComponents: any = {
    art_of_a_perfect_meal: ArtOfAPerfectMealSlice,
    articles_hero: ArticlesHeroSlice,
    articles_library: ArticlesLibrarySlice,
    banner_cta: BannerCtaSlice,
    basic_hero: BasicHeroSlice,
    basic_slider: BasicSliderSlice,
    call_to_action: CallToActionSlice,
    cards_cta: CardsCtaSlice,
    community_involvement: CommunityInvolvementSlice,
    crew_callout: CrewCalloutSlice,
    crew_rising: CrewRisingSlice,
    descriptive_slider: DescriptiveSliderSlice,
    double_cta: DoubleCtaSlice,
    dynamic_cta: DynamicCtaSlice,
    ecardwidget: ECardWidgetSlice,
    embedded_content: EmbeddedContentSlice,
    faq: FaqSlice,
    faq_category: FaqCategorySlice,
    faq_cta: FaqCtaSlice,
    hero_slider: HeroSliderSlice,
    how_it_works: HowItWorksSlice,
    highlights: HighlightsSlice,
    host_a_fundraiser: HostFundraiserSlice,
    image_grid: ImageGridSlice,
    image_reel: ImageReelSlice,
    image_strip: ImageStripSlice,
    locator: LocatorSlice,
    logo_cta: LogoCTASlice,
    menu_item_group: MenuItemGroupSlice,
    minimal_hero: MinimalHeroSlice,
    newsroom_articles_library: NewsroomArticlesLibrarySlice,
    profile_highlight: ProfileHighlightSlice,
    quote: QuoteSlice,
    recent_articles: RecentArticlesSlice,
    rich_text_cta: RichTextCtaSlice,
    saucy_carousel: SaucyCarouselSlice,
    secondary_navigation: SecondaryNavigationSlice,
    stat_grid: StatGridSlice,
    timeline: TimelineSlice,
    title_and_description: TitleAndDescriptionSlice,
    upcoming_restaurants: UpcomingRestaurantsSlice,
  }

  const sliceZoneContent = sliceZone.map(
    (slice: PrismicSliceType, index: number) => {
      const SliceComponent = sliceComponents[slice.slice_type]
      const key = `slice-${slice.id ?? index}`
      if (SliceComponent) {
        return <SliceComponent slice={slice} key={key} />
      }
      return null
    }
  )

  return (
    <div data-testid="slice-zone" className="container">
      {sliceZoneContent}
    </div>
  )
}

export default SliceZone
