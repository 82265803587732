import React, { useState } from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import BertholdTitle from 'src/components/atoms/bertholdTitle'
import { generateRandomId } from 'src/utils/domHelper'

import * as Styles from './descriptiveCard.module.scss'

type Props = {
  title?: string
  description?: string
  image?: IGatsbyImageData
  alt: string
  descriptionDisplay?: 'on' | 'toggle' | string
  scaleHeadlineFont?: boolean
}

const DescriptiveCard = ({
  title = '',
  description = '',
  image,
  alt,
  descriptionDisplay = 'toggle',
  scaleHeadlineFont = false,
}: Props) => {
  const [open, setOpen] = useState(descriptionDisplay === 'on')

  let descriptiveCardClass = Styles.descriptiveCard
  if (descriptionDisplay === 'toggle') {
    descriptiveCardClass += ` ${Styles.toggle}`
    if (!open) {
      descriptiveCardClass += ` ${Styles.descriptionClosed}`
    }
  }

  let headlineClass = Styles.headline

  if (scaleHeadlineFont) {
    headlineClass += ` ${Styles.scaleHeadlineFont}`
  }

  const descriptionId = `card-description-${generateRandomId()}`

  const renderCopy = () => {
    if (descriptionDisplay === 'toggle') {
      return (
        <div
          className={`${Styles.copy} $`}
          role="button"
          aria-expanded={open}
          aria-controls={descriptionId}
          onClick={() => setOpen(!open)}
          onKeyDown={e => {
            if (e.code === 'Enter') setOpen(!open)
          }}
          tabIndex={0}
        >
          {title && (
            <h3 className={headlineClass}>{BertholdTitle({ text: title })}</h3>
          )}
          {description && (
            <div id={descriptionId} className={Styles.description}>
              {description}
            </div>
          )}
        </div>
      )
    }

    return (
      <div className={Styles.copy}>
        {title && (
          <h3 className={headlineClass}>{BertholdTitle({ text: title })}</h3>
        )}
        {description && (
          <div id={descriptionId} className={Styles.description}>
            {description}
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={descriptiveCardClass}>
      <div className={Styles.imageWrapper}>
        {!!image && (
          <GatsbyImage className={Styles.image} image={image} alt={alt ?? ''} />
        )}
      </div>
      {renderCopy()}
    </div>
  )
}

export default DescriptiveCard
