import { PrismicRichText } from '@prismicio/react'
import React from 'react'
import { PrismicStructuredTextType } from 'src/typings/generated/graphql'
import CustomLink from 'src/utils/customLink'

import Eyebrow from 'src/images/svgs/Eyebrow.svg'
import { generateRandomId } from 'src/utils/domHelper'
import * as Styles from './titleAndDescription.module.scss'

export enum TitleAndDescriptionTextAlign {
  left = 'left',
  center = 'center',
}

export enum TitleAndDescriptionBackgroundColor {
  white = 'white',
  black = 'black',
}

export type TitleAndDescriptionContent = {
  anchor?: string
  description?: PrismicStructuredTextType
  eyebrowText?: string
  title?: string
  textAlign?: string
  titleAsH1?: boolean
  backgroundColor?: string
  hideEyebrow?: boolean
}
type Props = {
  content: TitleAndDescriptionContent
}

const TitleAndDescription = ({ content }: Props) => {
  const {
    anchor = generateRandomId(),
    description,
    eyebrowText = '',
    title = '',
    textAlign = TitleAndDescriptionTextAlign.left,
    titleAsH1 = false,
    backgroundColor = TitleAndDescriptionBackgroundColor.white,
    hideEyebrow = false,
  } = content

  let sectionClass = Styles.titleAndDescription

  const textAlignFormatted = textAlign.toLowerCase()
  if (textAlignFormatted === TitleAndDescriptionTextAlign.center) {
    sectionClass += ` ${Styles.centerContent}`
  }

  const backgroundColorFormatted = backgroundColor.toLowerCase()
  if (backgroundColorFormatted === TitleAndDescriptionBackgroundColor.black) {
    sectionClass += ` ${Styles.blackBackground}`
  }

  let titleClass = Styles.title
  if (!description) {
    titleClass += ` ${Styles.noDescription}`
  }

  // Render

  const renderEyebrowContent = () => {
    if (!eyebrowText)
      return (
        <>
          <Eyebrow />
          <Eyebrow />
        </>
      )

    return eyebrowText
  }

  return (
    <section id={anchor} className={sectionClass}>
      {(title || eyebrowText) && (
        <div className={Styles.titleContainer}>
          <div className={Styles.titleCopy}>
            {!hideEyebrow && (
              <div className={Styles.eyebrow}>{renderEyebrowContent()}</div>
            )}
            {titleAsH1 && <h1 className={titleClass}>{title}</h1>}
            {!titleAsH1 && <h2 className={titleClass}>{title}</h2>}
          </div>
        </div>
      )}
      <div className={Styles.bodyContainer}>
        <PrismicRichText
          field={description?.richText}
          components={{
            hyperlink: ({ node, children }: any) =>
              CustomLink({ link: node.data, children }),
          }}
        />
      </div>
    </section>
  )
}

export default TitleAndDescription
