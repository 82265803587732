// extracted by mini-css-extract-plugin
export var content = "heroSlider-module--content--ca11e";
export var contentWrapper = "heroSlider-module--content-wrapper--46e70";
export var desktopImage = "heroSlider-module--desktop-image--16f82";
export var headline = "heroSlider-module--headline--2f914";
export var hero = "heroSlider-module--hero--942ae";
export var heroSliderSlice = "heroSlider-module--hero-slider-slice--ef1fe";
export var media = "heroSlider-module--media--9eeca";
export var mediaWrapper = "heroSlider-module--media-wrapper--8a764";
export var mobile = "heroSlider-module--mobile--5ecab";
export var mobileImage = "heroSlider-module--mobile-image--ace7b";
export var preHeadline = "heroSlider-module--pre-headline--0b831";
export var slide = "heroSlider-module--slide--ed4d7";
export var slideContainer = "heroSlider-module--slide-container--00c1c";
export var slider = "heroSlider-module--slider--40a02";
export var sliderContainer = "heroSlider-module--slider-container--29461";
export var trackSingular = "heroSlider-module--track-singular--cb0fa";
export var videoControl = "heroSlider-module--video-control--ff0fd";
export var web = "heroSlider-module--web--70fd7";